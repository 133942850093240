import { h, Component } from 'preact';
import PropTypes from 'prop-types';

import ScheduleServiceForm from './ScheduleServiceForm';

export default class ScheduleServiceFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
  }

  handleCloseOverlay() {
    this.setState({ showOverlay: false });

    const { link } = this.props;
    const { errorSendingForm } = this.state;

    if (link && !errorSendingForm) {
      window.open(link, '_self');
    }
  }

  handleSubmit(e, params) {
    this.setState({ isSubmittingForm: true });

    return serviceConversion
      .convert(params)
      .then(() => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: false
        });
      })
      .catch(error => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: true
        });
        console.error(error);
      })
      .then(() => {
        this.setState({
          showOverlay: true
        });
      });
  }

  render() {
    const { formId, services, product, channel, category } = this.props;

    return (
      <ScheduleServiceForm
        formId={formId}
        handleSubmit={this.handleSubmit}
        isSubmittingForm={this.state.isSubmittingForm}
        errorSendingForm={this.state.errorSendingForm}
        handleCloseOverlay={this.handleCloseOverlay}
        showOverlay={this.state.showOverlay}
        services={services}
        product={product}
        channel={channel}
        category={category}
        brand={this.props.brand}
        units={this.props.units}
        showUnits={this.props.showUnits}
        showCpf={this.props.showCpf}
        versions={this.props.versions}
        contactsWithDynamicRequired={this.props.contactsWithDynamicRequired}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        showLocationFields={this.props.showLocationFields}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        filterUnitsByLocation={this.props.filterUnitsByLocation}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
      />
    );
  }
}

ScheduleServiceFormApp.defaultProps = {
  services: [],
  brand: '-',
  units: [],
  showUnits: true,
  showCpf: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  showLocationFields: false,
};

ScheduleServiceFormApp.propTypes = {
  formId: PropTypes.string,
  services: PropTypes.array,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showLocationFields: PropTypes.bool,
};
