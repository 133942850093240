import { h, Component } from 'preact';
import PropTypes from 'prop-types';

import WhatsAppForm from './WhatsAppForm';
import { isSafari } from '%/utils/isSafari';

class WhatsAppFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
  }

  handleCloseOverlay() {
    this.setState({ showOverlay: false });
  }

  openWhatsAppLink() {
    const { category } = this.props;
    const { errorSendingForm } = this.state;

    var link = this.props.link;

    // se encontrar o combobox verifica a necessidade de alterar o link
    if (
      document.querySelectorAll(
        '.choices__item.choices__item--selectable',
      )[0] != undefined
    ) {
      var selected_unit = document.querySelectorAll(
        '.choices__item.choices__item--selectable',
      )[0].dataset.value;
      if (this.props.phones) {
        this.props.phones.forEach(function (current) {
          if (current.area == category && current.unit == selected_unit) {
            link = current.link;
          }
        });
      }
    }

    if (link && !errorSendingForm) {
      isSafari() ? (location.href = link) : window.open(link, '_blank');
    }
  }

  handleSubmit(e, params) {
    this.setState({ isSubmittingForm: true });

    return serviceConversion
      .convert(params)
      .then(() => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: false,
        });
        this.openWhatsAppLink();
      })
      .catch(error => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: true,
        });
        console.error(error);
      })
      .then(() => {
        this.setState({
          showOverlay: false,
        });
      });
  }

  render() {
    const {
      formId,
      units,
      filterUnitsByLocation,
      unit,
      product,
      channel,
      category,
      whatsAppResponder,
    } = this.props;

    return (
      <WhatsAppForm
        showEmail={this.props.showEmail}
        showContactOptions={this.props.showContactOptions}
        formId={formId}
        handleSubmit={this.handleSubmit}
        isSubmittingForm={this.state.isSubmittingForm}
        errorSendingForm={this.state.errorSendingForm}
        handleCloseOverlay={this.handleCloseOverlay}
        showOverlay={this.state.showOverlay}
        filterUnitsByLocation={filterUnitsByLocation}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        units={units}
        unit={unit}
        product={product}
        channel={channel}
        category={category}
        brand={this.props.brand}
        country={this.props.country}
        bait={this.props.bait}
        whatsAppResponder={whatsAppResponder}
        showUnits={this.props.showUnits}
        showCpf={this.props.showCpf}
        cloneId={this.props.cloneId}
        versions={this.props.versions}
        customFields={this.props.customFields}
        showLocationFields={this.props.showLocationFields}
        contactsWithDynamicRequired={this.props.contactsWithDynamicRequired}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        modelCategory={this.props.modelCategory}
      />
    );
  }
}

WhatsAppFormApp.defaultProps = {
  units: [],
  brand: '-',
  showUnits: true,
  showCpf: false,
  versions: [],
  showEmail: true,
  showContactOptions: true,
  showLocationFields: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
};

WhatsAppFormApp.propTypes = {
  formId: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  unit: PropTypes.unit,
  filterUnitsByLocation: PropTypes.bool,
  product: PropTypes.string,
  channel: PropTypes.string,
  showEmail: PropTypes.bool,
  showContactOptions: PropTypes.bool,
  category: PropTypes.string,
  brand: PropTypes.string,
  country: PropTypes.string,
  bait: PropTypes.string,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showLocationFields: PropTypes.bool,
  phones: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      unit: PropTypes.string,
      area: PropTypes.string,
    }),
  ),
  cloneId: PropTypes.string,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
};

export default WhatsAppFormApp;
