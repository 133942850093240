import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import ScheduleServiceFormApp from '#/ScheduleServiceFormApp';
import MicroModal from 'micromodal';

export default class ScheduleServiceFormModal extends Component {
  constructor(props) {
    super(props);

    this.renderForm = this.renderForm.bind(this);
  }

  componentDidMount() {
    if (this.props.open) {
      this.modal = MicroModal.show(this.props.modalId);
    }
  }

  componentDidUpdate() {
    if (this.props.open) {
      this.modal = MicroModal.show(this.props.modalId);
    } else {
      this.modal = MicroModal.close(this.props.modalId);
    }
  }

  renderForm() {
    return (
      <ScheduleServiceFormApp
        formId={this.props.modalId}
        services={this.props.services}
        product={this.props.product}
        category={this.props.category}
        brand={this.props.brand}
        link={this.props.link}
        units={this.props.units}
        showUnits={this.props.showUnits}
        showCpf={this.props.showCpf}
        versions={this.props.versions}
        contactsWithDynamicRequired={this.props.contactsWithDynamicRequired}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        showLocationFields={this.props.showLocationFields}
        filterUnitsByLocation={this.props.filterUnitsByLocation}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
      />
    );
  }

  render() {
    return (
      <div
        class="modal micromodal-slide"
        id={this.props.modalId}
        aria-hidden="true"
      >
        <div class="modal__overlay" tabindex="-1" data-micromodal-close>
          <div className="position-relative">
            <i
              className="modal__close modal__overlay-close--form icon icon-close"
              data-micromodal-close
            />
            {this.renderForm()}
          </div>
        </div>
      </div>
    );
  }
}

ScheduleServiceFormModal.defaultProps = {
  modalId: 'modal-services',
  open: false,
  services: [],
  brand: '-',
  units: [],
  showUnits: true,
  showCpf: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  showLocationFields: false
};

ScheduleServiceFormModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  services: PropTypes.array,
  product: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  link: PropTypes.string,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showLocationFields: PropTypes.bool,
};
