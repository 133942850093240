import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Scrollbars from '../Scrollbars';
import { removeFilter, setList } from '../../stores/SearchFilter';

class DropdownFilter extends Component {
  constructor(props) {
    super(props);

    this.handleChangeCategories = this.handleChangeCategories.bind(this);
    this.handleChangeModels = this.handleChangeModels.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
  }

  getCategories() {
    const { filterCategories } = this.props;
    return typeof filterCategories === 'string'
      ? filterCategories.split(',')
      : filterCategories;
  }

  showFilterCategories() {
    return this.getCategories().length > 0;
  }

  showFilterModels() {
    return this.props.filterModels.length > 0;
  }

  handleChangeCategories(event) {
    const categoriesSelected = [...this.props.categoriesSelected];
    let index;

    if (event.target.checked) {
      categoriesSelected.push(event.target.value);
    } else {
      index = categoriesSelected.indexOf(event.target.value);
      categoriesSelected.splice(index, 1);
    }

    this.props.onChangeCategories(categoriesSelected);

    if (window.matchMedia('(max-width: 768px)').matches) {
      this.props.onClose();
    }
  }

  handleClearFilters() {
    this.props.onChangeModels([]);
    this.props.onChangeCategories([]);

    if (window.matchMedia('(max-width: 768px)').matches) {
      this.props.onClose();
    }
  }

  handleChangeModels(event) {
    const modelsSelected = [...this.props.modelsSelected];
    const { checked, value } = event.target;

    if (checked) {
      modelsSelected.push(value);
    } else {
      const index = modelsSelected.indexOf(value);
      modelsSelected.splice(index, 1);
    }

    this.props.onChangeModels(modelsSelected);
  }

  addEventListeners() {
    window.addEventListener('click', event => {
      if (
        this.ref &&
        event.target !== this.ref &&
        !this.ref.contains(event.target) &&
        this.props.showFilter
      ) {
        this.props.onClose();
      }
    });
  }

  componentDidMount() {
    this.addEventListeners();
    if (this.props.showFilter) {
      document.body.classList.add('no-scroll');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.showFilter && !prevProps.showFilter) {
      document.body.classList.add('no-scroll');
    } else if (!this.props.showFilter && prevProps.showFilter) {
      document.body.classList.remove('no-scroll');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('no-scroll');
  }

  render() {
    const { children, className, showFilter, filterModels } = this.props;
    const showFiltersCleanerButton = Boolean(
      this.props.showFiltersCleaner &&
        this.props.categoriesSelected.length &&
        window.matchMedia('(max-width: 768px)').matches,
    );

    return (
      <div>
        <div
          className={classNames('backdrop', {
            'backdrop--active': showFilter,
          })}
          onClick={this.props.onClose}
        />
        <div
          ref={node => (this.ref = node)}
          className={classNames('dropdown-filter', className, {
            'search-filter-simple__drop': true,
            'search-filter-simple__item': true,
            'search-filter-simple__drop--active': showFilter,
          })}
        >
          {children}

          <div className="card-collapse-deep search-filter-simple__drop-item">
            <div className="card-collapse-deep__header">Filtrar por:</div>
            <div
              className="card-collapse-deep__close"
              style={{ display: 'none' }}
            >
              <button
                className="btn btn--icon btn--icon--small"
                onClick={this.props.onClose}
              >
                <i className="icon icon-close" />
              </button>
            </div>

            <ul className="list list--border-bottom">
              {this.showFilterCategories() && (
                <li>
                  <strong className="dropdown-filter__list-title">
                    Categoria
                  </strong>
                  <Scrollbars autoHeight>
                    <ul className="list list--no-style">
                      {this.getCategories().map(category => (
                        <li>
                          <div className="custom-check custom-check--checkbox justify-content-start">
                            <input
                              type="checkbox"
                              value={category}
                              checked={this.props.categoriesSelected.includes(
                                category,
                              )}
                              onChange={this.handleChangeCategories}
                            />
                            <div className="custom-check__check" />
                            <label>{category}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Scrollbars>
                </li>
              )}
              {this.showFilterModels() && (
                <li>
                  <strong className="dropdown-filter__list-title">
                    Modelo
                  </strong>
                  <Scrollbars autoHeight>
                    <ul className="list list--no-style">
                      {filterModels.map(model => (
                        <li key={model}>
                          <div className="custom-check custom-check--checkbox justify-content-start">
                            <input
                              type="checkbox"
                              value={model}
                              checked={this.props.modelsSelected.includes(
                                model,
                              )}
                              onChange={this.handleChangeModels}
                            />
                            <div className="custom-check__check" />
                            <label>{model}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Scrollbars>
                </li>
              )}
            </ul>
            {showFiltersCleanerButton && (
              <button
                type="button"
                className="result-search-simple__filter-cleaner"
                onClick={this.handleClearFilters}
              >
                Limpar filtros
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

DropdownFilter.defaultProps = {
  categoriesSelected: [],
  modelsSelected: [],
  filterCategories: [],
  filterModels: [],
  showFilter: false,
  showFiltersCleaner: false,
  onChangeCategories() {},
  onchangeModels() {},
  onClose() {},
};

DropdownFilter.propTypes = {
  categoriesSelected: PropTypes.array.isRequired,
  modelsSelected: PropTypes.array.isRequired,
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filterModels: PropTypes.array,
  showFilter: PropTypes.bool,
  showFiltersCleaner: PropTypes.bool,
  onChangeCategories: PropTypes.func,
  onchangeModels: PropTypes.func,
  onClose: PropTypes.func,
};

export default DropdownFilter;
