import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import WhatsAppFormApp from '#/WhatsAppFormApp';
import CTABox from './CTABox';
import CTABoxWithModel from './Whatsapp/CallBox/BoxWithModel';
import MicroModal from 'micromodal';

export default class WhatsAppBoxWithModal extends Component {
  constructor(props) {
    super(props);
    this.handleOpenModalClick = this.handleOpenModalClick.bind(this);
    this.renderWhatsAppForm = this.renderWhatsAppForm.bind(this);
  }

  handleOpenModalClick(event) {
    event.preventDefault();

    if (this.props.openInteractiveWhatsappForm) {
      window.renderInteractiveWhatsappForm(event);
      return;
    }

    if (this.props.openModal) {
      MicroModal.show(this.props.modalId);
    } else {
      window.open(event.target.getAttribute('href'), '_blank');
    }
  }

  renderWhatsAppForm() {
    return (
      <WhatsAppFormApp
        showEmail={this.props.showEmail}
        showContactOptions={this.props.showContactOptions}
        formId={this.props.modalId}
        bait={this.props.bait}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        units={this.props.units}
        unit={this.props.unit}
        product={this.props.product}
        channel={this.props.channel}
        category={this.props.category}
        brand={this.props.brand}
        country={this.props.country}
        link={this.props.link}
        showUnits={this.props.showUnits}
        showCpf={this.props.showCpf}
        cloneId={this.props.cloneId}
        versions={this.props.versions}
        customFields={this.props.customFields}
        showLocationFields={this.props.showLocationFields}
        contactsWithDynamicRequired={this.props.contactsWithDynamicRequired}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        filterUnitsByLocation={this.props.filterUnitsByLocation}
        modelCategory={this.props.modelCategory}
      />
    );
  }

  render() {
    return (
      <div>
        {this.props.boxWithModel ? (
          <CTABoxWithModel
            handleClick={this.handleOpenModalClick}
            buttonId={
              this.props.buttonId
                ? this.props.buttonId
                : `${this.props.modalId}-button`
            }
            direction={this.props.direction}
            buttonText={this.props.buttonText}
            modelImage={this.props.modelImage}
            subtitle={this.props.ctaBoxSubtitle}
            link={this.props.link}
          />
        ) : (
          <CTABox
            handleClick={this.handleOpenModalClick}
            buttonId={
              this.props.buttonId
                ? this.props.buttonId
                : `${this.props.modalId}-button`
            }
            direction={this.props.direction}
            buttonText={this.props.buttonText}
            link={this.props.link}
          />
        )}

        <div
          class="modal micromodal-slide"
          id={this.props.modalId}
          aria-hidden="true"
        >
          <div class="modal__overlay" tabindex="-1" data-micromodal-close>
            <div className="position-relative">
              <i
                className="modal__close modal__overlay-close--form icon icon-close"
                data-micromodal-close
              />
              {this.renderWhatsAppForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WhatsAppBoxWithModal.defaultProps = {
  modalId: 'modal-whatsapp',
  units: [],
  brand: '-',
  showUnits: true,
  showCpf: false,
  showLocationFields: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  openModal: true,
  showEmail: true,
  showContactOptions: true,
  openInteractiveWhatsappForm: false,
};

WhatsAppBoxWithModal.propTypes = {
  modalId: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  unit: PropTypes.string,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  showEmail: PropTypes.bool,
  showContactOptions: PropTypes.bool,
  brand: PropTypes.string,
  link: PropTypes.string,
  bait: PropTypes.string,
  country: PropTypes.string,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  buttonText: PropTypes.string,
  boxWithModel: PropTypes.bool,
  modelImage: PropTypes.string,
  showLocationFields: PropTypes.bool,
  ctaBoxSubtitle: PropTypes.string,
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  showUpload: PropTypes.bool,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  openModal: PropTypes.bool,
  openInteractiveWhatsappForm: PropTypes.bool,
};
