import { h } from 'preact';
import { Component } from 'react';
import ConversionForm from '#/ConversionForm';
import PropTypes from 'prop-types';

export default class ConsortiumConversionFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false
    };

    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCloseOverlay(e) {
    this.setState({ showOverlay: false });
  }

  handleSubmit(e, params) {
    this.setState({ isSubmittingForm: true }, () => {

      let {
        monthlyValue,
        selectedMonths,
        totalValue,
        plans
      } = window.store.Consortium.getState();

      const product = plans.title,
        model_car = plans.version_id

      return serviceConversion
        .convert({
          ...params,
          bait: '-3',
          category: 'Consortium',
          value_per_month: monthlyValue,
          months: selectedMonths,
          total_value: totalValue,
          product,
          model_car
        })
        .then(() => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: false
          });
        })
        .catch(error => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: true
          });
          console.error(error);
        })
        .then(() => {
          this.setState({
            showOverlay: true
          });
        });
    });
  }

  render() {
    return (
      <ConversionForm
        formId="conversion-from-new-model"
        isSubmittingForm={this.state.isSubmittingForm}
        errorSendingForm={this.state.errorSendingForm}
        showOverlay={this.state.showOverlay}
        handleSubmit={this.handleSubmit}
        handleCloseOverlay={this.handleCloseOverlay}
        titleForm={this.props.titleForm}
        mainPhrase={this.props.mainPhrase}
        shouldShowImage={this.props.shouldShowImage}
        shouldShowPrice={this.props.shouldShowPrice}
        shouldShowVariantSelect={false}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        units={this.props.units}
        product={this.props.product}
        channel={this.props.channel}
        category={this.props.category}
        brand={this.props.brand}
        showUnits={this.props.showUnits}
        showCpf={this.props.showCpf}
        showLastName={this.props.showLastName}
        cloneId={this.props.cloneId}
        showVehicleExchange={this.props.showVehicleExchange}
        showLabels={this.props.showLabels}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        showLocationFields={this.props.showLocationFields}
        buttonLabel={this.props.buttonLabel}
      />
    );
  }
}

ConsortiumConversionFormApp.defaultProps = {
  shouldShowImage: false,
  shouldShowPrice: false,
  showLastName: false,
  units: [],
  brand: '-',
  showUnits: true,
  showCpf: false,
  showVehicleExchange: false,
  showLabels: false,
  shouldShowDataPermissions: false,
  showLocationFields: false
};

ConsortiumConversionFormApp.propTypes = {
  linkPrivacyPolicy: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showLastName: PropTypes.bool,
  showVehicleExchange: PropTypes.bool,
  showLabels: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  showLocationFields: PropTypes.bool
};
