/* eslint jsx-a11y/label-has-associated-control: 0 */ // --> OFF

import { Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import wNumb from 'wnumb';
import handleInputChange from '../../utils/handleInputChange';
import { getPrefix, getSuffix } from '../../utils/currency';
import isMobile from '../../utils/isMobile';
import CustomSelect from '../CustomSelect';
import InputRange from '../InputRange';
import CustomCheck from '../CustomCheck';

const moneyFormat = wNumb({
  decimals: 0,
  thousand: '.',
  prefix: getPrefix(),
  suffix: getSuffix(),
});

const kmFormat = wNumb({
  decimals: 0,
  thousand: '.',
  suffix: 'Km',
});

export const removeClassOpen = () => {
  document
    .querySelector('.search-filter-sidebar')
    .classList.remove('search-filter-sidebar--open');
};

export const addClassOpen = () => {
  document
    .querySelector('.search-filter-sidebar')
    .classList.add('search-filter-sidebar--open');
};

export default class SearchFilterSidebar extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      listing: 'seminovos',
      q: null,
      kind: '',
      category: '',
      brand: '',
      model: '',
      color: '',
      exchange: '',
      minPrice: this.props.minPrice,
      maxPrice: this.props.maxPrice,
      minKm: this.props.minKm,
      maxKm: this.props.maxKm,
      minYear: this.props.minYear,
      maxYear: this.props.maxYear,
      unit: '',
      state: '',
      city: '',
      cities: this.props.cities,
      citiesOptions: [],
      unitsOptions: [],
      selectOrderBy: '',
      selectPerPage: '',
      disableSelectModels: true,
      disableSelectCities: this.props.states.length > 1, // habilita o select caso tenha mais de um estado
      checkPrice: true,
      checkKm: true,
      checkYear: true,
    };

    this.state = {
      ...this.initialState,
      ...this.props.initialFilters,
      advancedFilterOpen:
        this.props.initialFilters.city ||
        this.props.initialFilters.unit ||
        false,
    };

    if (this.props.states.length === 1) {
      this.setState({
        citiesOptions: this.props.cities[this.props.states[0].value],
      });
    }

    if (this.state.state && this.state.state !== '') {
      this.setState(previousState => ({
        citiesOptions: this.props.cities[previousState.state],
        disableSelectCities: false,
      }));
    }

    this.handleInputChange = handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleKindsChange = this.handleKindsChange.bind(this);
    this.handleCategoriesChange = this.handleCategoriesChange.bind(this);
    this.handleExchangesChange = this.handleExchangesChange.bind(this);
    this.handleBrandsChange = this.handleBrandsChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleInputRangePriceChange =
      this.handleInputRangePriceChange.bind(this);
    this.handleInputRangeKmChange = this.handleInputRangeKmChange.bind(this);
    this.handleInputRangeYearChange =
      this.handleInputRangeYearChange.bind(this);
    this.handleCheckPrice = this.handleCheckPrice.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleListingsChange = this.handleListingsChange.bind(this);
    this.handleColorsChange = this.handleColorsChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { minPrice, maxPrice, minKm, maxKm, minYear, maxYear } = this.props;

    if (prevProps.minPrice !== minPrice || prevProps.maxPrice !== maxPrice) {
      const newMinPrice = this.props.initialFilters.minPrice || minPrice;
      const newMaxPrice = this.props.initialFilters.maxPrice || maxPrice;
      this.refRangePrice.noUiSlider.set([newMinPrice, newMaxPrice]);
    }

    if (prevProps.minKm !== minKm || prevProps.maxKm !== maxKm) {
      const newMinKm = this.props.initialFilters.minKm || minKm;
      const newMaxKm = this.props.initialFilters.maxKm || maxKm;
      this.refRangeKm.noUiSlider.set([newMinKm, newMaxKm]);
    }

    if (prevProps.minYear !== minYear || prevProps.maxYear !== maxYear) {
      const newMinYear = this.props.initialFilters.minYear || minYear;
      const newMaxYear = this.props.initialFilters.maxYear || maxYear;
      this.refRangeYear.noUiSlider.set([newMinYear, newMaxYear]);
    }

    this.initialState = {
      ...this.initialState,
      minPrice,
      maxPrice,
      minKm,
      maxKm,
      minYear,
      maxYear,
    };
  }

  handleModelChange(e) {
    // necessário porque quando o select não tem options não teve mudar o valor no state
    const { name, value } = e.target;
    if (this.models.length === 0 || this.state.model === value) return;

    this.setState({ [name]: value });

    if (value && !this.props.isHomePage) this.handleSubmit(e);
  }

  handleInputRangePriceChange(values) {
    const [minPrice, maxPrice] = values;
    this.setState({ minPrice, maxPrice });
  }

  handleInputRangeKmChange(values) {
    const [minKm, maxKm] = values;
    this.setState({ minKm, maxKm });
  }

  handleInputRangeYearChange(values) {
    const [minYear, maxYear] = values;
    this.setState({
      minYear: parseInt(minYear, 10),
      maxYear: parseInt(maxYear, 10),
    });
  }

  handleCheckPrice(e) {
    const { checked } = e.target;
    this.setState({ checkPrice: checked });
  }

  handleReset(e) {
    e.preventDefault();

    this.setState({
      ...this.initialState,
    });

    const { minPrice, maxPrice, minKm, maxKm, minYear, maxYear } =
      this.initialState;

    this.refRangePrice.noUiSlider.set([minPrice, maxPrice]);
    this.refRangeKm.noUiSlider.set([minKm, maxKm]);
    this.refRangeYear.noUiSlider.set([minYear, maxYear]);

    this.props.onReset(this.initialState);
  }

  handleFilter(e) {
    e.preventDefault();

    this.setState(previousState => ({
      advancedFilterOpen: !previousState.advancedFilterOpen,
    }));
  }

  handleClose(e) {
    e.preventDefault();
    removeClassOpen();
  }

  handleSubmit(e) {
    e.preventDefault();

    const eventType = e.type;
    const isMobileScreen = isMobile();

    if (isMobileScreen && eventType === 'click') {
      removeClassOpen();
    }

    const data = {
      q: this.state.q,
      kind: this.state.kind,
      category: this.state.category,
      brand: this.state.brand,
      model: this.state.model,
      color: this.state.color,
      exchange: this.state.exchange,
      state: this.state.state,
      city: this.state.city,
      unit: this.state.unit,
      selectOrderBy: this.state.selectOrderBy,
      selectPerPage: this.state.selectPerPage,
    };

    if (this.props.hasTypeVehicles) {
      data.listing = this.state.listing;
    }

    if (this.state.checkPrice) {
      data.minPrice = moneyFormat.from(this.state.minPrice);
      data.maxPrice = moneyFormat.from(this.state.maxPrice);
    }

    if (this.state.checkKm) {
      data.minKm = kmFormat.from(this.state.minKm);
      data.maxKm = kmFormat.from(this.state.maxKm);
    }

    if (this.state.checkYear) {
      data.minYear = this.state.minYear;
      data.maxYear = this.state.maxYear;
    }

    this.props.onSubmit(data);
  }

  handleBrandsChange(e) {
    // necessário porque quando o select não tem options não teve mudar o valor no state
    const { value } = e.target;
    if (this.brands.length === 0) return;
    const shouldSubmit = this.state.brand !== value && !this.props.isHomePage;

    this.setState({
      brand: value,
      model: '', // dica: aqui deve ser um string vazia, se passar null o select não limpa
      disableSelectModels: !value,
    });

    if (value && shouldSubmit) this.handleSubmit(e);
  }

  handleKindsChange(e) {
    const { value } = e.target;
    if (this.kinds.length === 0) return;
    const shouldSubmit = this.state.kind !== value && !this.props.isHomePage;

    this.setState({ kind: value });
    if (value && shouldSubmit) this.handleSubmit(e);
  }

  handleCategoriesChange(e) {
    const { value } = e.target;
    if (this.categories.length === 0) return;
    const shouldSubmit =
      this.state.category !== value && !this.props.isHomePage;

    this.setState({ category: value });
    if (value && shouldSubmit) this.handleSubmit(e);
  }

  handleExchangesChange(e) {
    const { value } = e.target;
    if (this.exchanges.length === 0) return;
    const shouldSubmit = this.state.Shift !== value && !this.props.isHomePage;

    this.setState({ exchange: value });
    if (value && shouldSubmit) this.handleSubmit(e);
  }

  handleListingsChange(e) {
    const { value } = e.target;
    this.setState({
      listing: value,
    });
  }

  async handleInputChangeFilter(e) {
    await this.handleInputChange(e);

    if (e.keyCode === 13) {
      await this.handleSubmit(e);
    }
  }

  handleSelectChange(e) {
    const { target } = e;

    const shouldSubmit =
      this.state[target.name] !== target.value && !this.props.isHomePage;
    this.setState({ [target.name]: target.value });

    if (target.value && shouldSubmit) this.handleSubmit(e);
  }

  handleStateChange(e) {
    const { target } = e;
    const stateText = target[target.selectedIndex].value;
    const valueCitiesOptions = this.props.cities[stateText] || []; // ex: Ceará, Pernambuco

    if (this.state.state === target.value) return;

    this.setState({
      state: target.value,
      unit: '',
      city: '',
      citiesOptions: valueCitiesOptions,
      disableSelectCities: !valueCitiesOptions.length,
      disableSelectUnits: true,
    });

    if (target.value && !this.props.isHomePage) this.handleSubmit(e);
  }

  handleColorsChange(e) {
    const { value } = e.target;
    if (this.colors.length === 0) return;
    const shouldSubmit = this.state.color !== value && !this.props.isHomePage;

    this.setState({ color: value });
    if (value && shouldSubmit) this.handleSubmit(e);
  }

  /**
   * Listagens usadas no select
   */
  get listings() {
    return [
      {
        label: 'Seminovos',
        value: 'seminovos',
      },
      {
        label: 'Novos',
        value: 'ofertas-especiais',
      },
      {
        label: 'Estoque',
        value: 'estoque',
      },
    ];
  }

  /**
   * Modelos usados no select
   */
  get models() {
    const listModels = this.props.models;
    const { brand } = this.state;

    if (!listModels[brand]) return [];

    return listModels[brand]
      .map(model => ({
        ...model,
        label: model.title,
        value: model.title,
      }))
      .filter(({ kind }) => {
        if (this.props.showVehicleTypeFilter && this.state.kind)
          return kind === this.state.kind;
        return true;
      });
  }

  /**
   * Marcas usadas no select
   */
  get brands() {
    const listAllOption = {
      label: 'Todas',
      value: '',
    };

    let listBrands = this.props.brands;

    if (this.props.showVehicleTypeFilter && this.state.kind) {
      const filteredBrands = this.props.brands.filter(
        brand => brand.kind === this.state.kind,
      );
      listBrands = filteredBrands;
    }

    const uniqueBrands = [...new Set(listBrands.map(brand => brand.title))];

    const brandOptions = uniqueBrands.map(brand => ({
      label: brand,
      value: brand,
    }));

    return [listAllOption, ...brandOptions];
  }

  /**
   * Tipos de veículos usados no select
   */
  get kinds() {
    const listAllOption = {
      label: 'Todos',
      value: '',
    };
    const kindOptions = this.props.kinds.map(kind => ({
      label: kind.title,
      value: kind.title,
    }));

    const kinds = [listAllOption, ...kindOptions];

    return kinds;
  }

  /**
   * Categorias usadas no select
   */
  get categories() {
    const listAllOption = {
      label: 'Todos',
      value: '',
    };
    const categoryOptions = this.props.categories.map(category => ({
      label: category.title,
      value: category.title,
    }));

    const categories = [listAllOption, ...categoryOptions];

    return categories;
  }

  /**
   * Cores usadas no select
   */
  get colors() {
    const listAllOption = {
      label: 'Todas',
      value: '',
    };

    const colorOptions = this.props.colors
      .filter(color => color.title !== '')
      .map(color => ({
        label: color.title,
        value: color.title,
      }));

    const colors = [listAllOption, ...colorOptions];

    return colors;
  }

  /**
   * Tipos de câmbio no select
   */
  get exchanges() {
    const listAllOption = {
      label: 'Todos',
      value: '',
    };
    const exchangeOptions = this.props.exchanges.map(exchange => ({
      label: exchange.title,
      value: exchange.title,
    }));

    const exchanges = [listAllOption, ...exchangeOptions];

    return exchanges;
  }

  render() {
    return (
      <div
        className={classNames('search-filter-sidebar', this.props.customClass)}
      >
        <form onSubmit={this.handleSubmit}>
          <button
            type="button"
            onClick={this.handleClose}
            className="search-filter-sidebar__close search-filter-sidebar--mobile-only"
          >
            <i className="icon icon-close" />
          </button>

          <h2 className="search-filter-sidebar__title text-center">
            Encontre seu veículo
          </h2>

          <div className="search-filter-sidebar__wrapper-reset">
            <button
              type="button"
              className="btn button button--large search-filter-sidebar__reset"
              onClick={this.handleReset}
            >
              <i className="icon icon-trash" />
              Limpar Filtros
            </button>

            <hr />
          </div>

          <div className="form-group">
            <input
              className="form-control form-control--line"
              type="text"
              name="q"
              value={this.state.q}
              placeholder="Buscar"
              onKeyUp={e => this.handleInputChangeFilter(e)}
            />
          </div>

          {this.props.hasTypeVehicles && (
            <div className="form-group">
              <label htmlFor="listing" className="control-label">
                Veículos:
              </label>
              <CustomSelect
                classNames={{
                  containerOuter: 'choices choices--line',
                }}
                name="listing"
                handleSelectChange={this.handleListingsChange}
                options={this.listings}
                value={this.state.listing}
                searchEnabled={false}
              />
            </div>
          )}

          {this.props.showVehicleTypeFilter && (
            <div className="form-group">
              <label htmlFor="kind" className="control-label">
                Tipo:
              </label>
              <CustomSelect
                classNames={{
                  containerOuter: 'choices choices--line',
                }}
                name="kind"
                handleSelectChange={this.handleKindsChange}
                options={this.kinds}
                value={this.state.kind}
                placeholderValue="Selecione"
                searchEnabled={false}
              />
            </div>
          )}

          {this.props.showCategoryFilter && (
            <div className="form-group">
              <label htmlFor="category" className="control-label">
                Categoria:
              </label>
              <CustomSelect
                classNames={{
                  containerOuter: 'choices choices--line',
                }}
                name="category"
                handleSelectChange={this.handleCategoriesChange}
                options={this.categories}
                value={this.state.category}
                placeholderValue="Selecione"
                searchEnabled={false}
              />
            </div>
          )}

          <div className="form-group">
            <label htmlFor="brand" className="control-label">
              Marca:
            </label>
            <CustomSelect
              classNames={{
                containerOuter: 'choices choices--line',
              }}
              name="brand"
              handleSelectChange={this.handleBrandsChange}
              options={this.brands}
              value={this.state.brand}
              placeholderValue="Selecione"
              searchEnabled={false}
            />
          </div>

          <div className="form-group">
            <label className="control-label">Modelo:</label>
            <CustomSelect
              classNames={{
                containerOuter: 'choices choices--line',
              }}
              name="model"
              handleSelectChange={this.handleModelChange}
              options={this.models}
              value={this.state.model}
              placeholderValue="Selecione"
              disable={this.state.disableSelectModels}
              searchEnabled={false}
            />
          </div>

          {this.props.states.length > 1 && (
            <div className="form-group">
              <label className="control-label">Estados:</label>
              <CustomSelect
                classNames={{
                  containerOuter: 'choices choices--line',
                }}
                name="states"
                handleSelectChange={this.handleStateChange}
                options={this.props.states}
                value={this.state.state}
                placeholderValue="Selecione"
                disable={false}
                searchEnabled={false}
              />
            </div>
          )}

          <div
            className={classNames({
              'search-filter-sidebar__inputs-hidden': true,
              'search-filter-sidebar__inputs-hidden--open':
                this.state.advancedFilterOpen,
            })}
          >
            <div className="form-group">
              <label className="control-label">Cidades:</label>
              <CustomSelect
                classNames={{
                  containerOuter: 'choices choices--line',
                }}
                name="city"
                handleSelectChange={this.handleSelectChange}
                options={this.state.citiesOptions}
                value={this.state.city}
                placeholderValue="Selecione"
                disable={this.state.disableSelectCities}
                searchEnabled={false}
              />
            </div>

            <div className="form-group">
              <label className="control-label">Lojas:</label>
              <CustomSelect
                classNames={{
                  containerOuter: 'choices choices--line',
                }}
                name="unit"
                handleSelectChange={this.handleSelectChange}
                options={this.props.units}
                value={this.state.unit}
                placeholderValue="Selecione"
                disable={false}
                searchEnabled={false}
              />
            </div>

            {this.props.colors && (
              <div className="form-group">
                <label className="control-label">Cor:</label>
                <CustomSelect
                  classNames={{
                    containerOuter: 'choices choices--line',
                  }}
                  name="color"
                  handleSelectChange={this.handleColorsChange}
                  options={this.colors}
                  value={this.state.color}
                  placeholderValue="Selecione"
                  disable={false}
                  searchEnabled={false}
                />
              </div>
            )}

            {this.props.exchanges && (
              <div className="form-group">
                <label className="control-label">Câmbio:</label>
                <CustomSelect
                  classNames={{
                    containerOuter: 'choices choices--line',
                  }}
                  name="exchange"
                  handleSelectChange={this.handleExchangesChange}
                  options={this.exchanges}
                  value={this.state.exchange}
                  placeholderValue="Selecione"
                  disable={false}
                  searchEnabled={false}
                />
              </div>
            )}

            <div className="form-group">
              <label className="control-label control-label--check">
                <CustomCheck
                  className="custom-check--shadow"
                  type="checkbox"
                  name="checkPrice"
                  checkStyle="line"
                  isChecked={this.state.checkPrice}
                  onChangeCheckable={this.handleInputChange}
                />
                Preço
              </label>
              <InputRange
                name="rangePrice"
                min={this.props.minPrice}
                max={this.props.maxPrice}
                onChange={this.handleInputRangePriceChange}
                value={[this.state.minPrice, this.state.maxPrice]}
                disabled={!this.state.checkPrice}
                step={1000}
                format={moneyFormat}
                onSetRef={ref => {
                  this.refRangePrice = ref;
                }}
              />
              <div className="search-filter-sidebar__values-input-range">
                <span>{this.state.minPrice}</span>
                <span>{this.state.maxPrice}</span>
              </div>
            </div>

            <hr />

            <div className="form-group">
              <label className="control-label control-label--check">
                <CustomCheck
                  className="custom-check--shadow"
                  type="checkbox"
                  name="checkKm"
                  checkStyle="line"
                  isChecked={this.state.checkKm}
                  onChangeCheckable={this.handleInputChange}
                />
                Quilometragem
              </label>
              <InputRange
                name="rangeKm"
                min={this.props.minKm}
                max={this.props.maxKm}
                onChange={this.handleInputRangeKmChange}
                value={[this.state.minKm, this.state.maxKm]}
                disabled={!this.state.checkKm}
                step={10}
                format={kmFormat}
                onSetRef={ref => {
                  this.refRangeKm = ref;
                }}
              />
              <div className="search-filter-sidebar__values-input-range">
                <span>{this.state.minKm}</span>
                <span>{this.state.maxKm}</span>
              </div>
            </div>

            <hr />

            <div className="form-group">
              <label className="control-label control-label--check">
                <CustomCheck
                  className="custom-check--shadow"
                  type="checkbox"
                  name="checkYear"
                  checkStyle="line"
                  isChecked={this.state.checkYear}
                  onChangeCheckable={this.handleInputChange}
                />
                Ano
              </label>
              <InputRange
                name="rangeYear"
                min={this.props.minYear}
                max={this.props.maxYear}
                onChange={this.handleInputRangeYearChange}
                value={[this.state.minYear, this.state.maxYear]}
                disabled={!this.state.checkYear}
                step={1}
                onSetRef={ref => {
                  this.refRangeYear = ref;
                }}
              />
              <div className="search-filter-sidebar__values-input-range">
                <span>{this.state.minYear}</span>
                <span>{this.state.maxYear}</span>
              </div>
            </div>

            <hr />

            <div className="form-group">
              <label className="control-label">Ordernar por:</label>
              <CustomSelect
                classNames={{
                  containerOuter: 'choices choices--line',
                }}
                name="selectOrderBy"
                handleSelectChange={this.handleSelectChange}
                options={this.props.orderBy}
                value={this.state.selectOrderBy}
                placeholderValue="Selecione"
                searchEnabled={false}
              />
            </div>

            <div className="form-group">
              <label className="control-label">Itens por página:</label>
              <CustomSelect
                classNames={{
                  containerOuter: 'choices choices--line',
                }}
                name="selectPerPage"
                handleSelectChange={this.handleSelectChange}
                options={this.props.perPage}
                value={this.state.selectPerPage}
                placeholderValue="Selecione"
                searchEnabled={false}
              />
            </div>
          </div>

          <hr />

          <div className="search-filter-sidebar__ctas">
            <button
              type="button"
              className="btn button button--large search-filter-sidebar__filter"
              onClick={this.handleFilter}
            >
              <i
                className={classNames({
                  icon: true,
                  'icon-filter': !this.state.advancedFilterOpen,
                  'icon-close': this.state.advancedFilterOpen,
                })}
              />
              Filtros Avançados
            </button>

            <button
              type="button"
              className="btn button search-filter-sidebar__reset d-md-none"
              onClick={this.handleReset}
            >
              <i className="icon icon-trash" />
              Limpar
            </button>

            <button
              type="submit"
              className="bnt button button--large search-filter-sidebar__submit"
              onClick={this.handleSubmit}
              disabled={this.props.isProcessing}
            >
              Buscar
              {this.props.isProcessing && (
                <div className="spinner-border ml-2 d-lg-none" />
              )}
              {this.props.isProcessing && (
                <div className="spinner-border ml-2 d-none d-lg-block" />
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

SearchFilterSidebar.defaultProps = {
  initialFilters: {},
  brands: [],
  colors: [],
  exchanges: [],
  models: {},
  minPrice: 0,
  maxPrice: 500000,
  minKm: 0,
  maxKm: 150000,
  minYear: 1990,
  maxYear: 2030,
  states: [],
  cities: [],
  units: [],
  kinds: [],
  categories: [],
  orderBy: [
    { label: 'Mais relevante', value: '-featured,-ordination' },
    { label: 'Maior preço', value: '-price' },
    { label: 'Menor preço', value: '+price' },
    { label: 'Marca A-Z', value: '+brand.keyword' },
    { label: 'Marca Z-A', value: '-brand.keyword' },
    { label: 'Modelo A-Z', value: '+name.keyword' },
    { label: 'Modelo Z-A', value: '-name.keyword' },
  ],
  perPage: [
    { label: 9, value: 9 },
    { label: 12, value: 12 },
    { label: 15, value: 15 },
    { label: 21, value: 21 },
    { label: 30, value: 30 },
  ],
  isProcessing: false,
  customClass: '',
  onSubmit() {},
  onReset() {},
  hasTypeVehicles: false,
  showVehicleTypeFilter: false,
  showCategoryFilter: false,
  isHomePage: false,
};

SearchFilterSidebar.propTypes = {
  customClass: PropTypes.string,
  initialFilters: PropTypes.objectOf,
  brands: PropTypes.arrayOf,
  models: PropTypes.objectOf,
  states: PropTypes.arrayOf,
  cities: PropTypes.arrayOf,
  colors: PropTypes.arrayOf,
  exchanges: PropTypes.arrayOf,
  kinds: PropTypes.arrayOf,
  categories: PropTypes.arrayOf,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  minKm: PropTypes.number,
  maxKm: PropTypes.number,
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  units: PropTypes.arrayOf,
  orderBy: PropTypes.arrayOf,
  perPage: PropTypes.arrayOf,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  isProcessing: PropTypes.bool,
  hasTypeVehicles: PropTypes.bool,
  showVehicleTypeFilter: PropTypes.bool,
  showCategoryFilter: PropTypes.bool,
  isHomePage: PropTypes.bool,
};
