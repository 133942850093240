import { h } from 'preact';
import PropTypes from 'prop-types';

function ResultSarchSimple({
  keyword,
  total,
  selectedCategories,
  showFilters = false,
  showFiltersCleaner = false,
  handleClearFilters,
}) {
  return (
    <div className="result-search-simple">
      {showFilters && selectedCategories.length > 0 && (
        <div className="result-search-simple__filters">
          Filtros:{' '}
          {selectedCategories.map(category => (
            <span key={category} className="result-search-simple__filter-name">
              {category}
            </span>
          ))}
          {showFiltersCleaner && (
            <button
              type="button"
              className="result-search-simple__filter-cleaner"
              onClick={handleClearFilters}
            >
              Limpar filtros
            </button>
          )}
        </div>
      )}
      {keyword && !showFilters && (
        <div>
          Buscando por{' '}
          <span className="result-search-simple__keyword">{keyword}</span>
        </div>
      )}
      <div className="result-search-simple__total-results">
        <span className="result-search-simple__total">{total}</span>{' '}
        {total > 1 ? 'resultados encontrados' : 'resultado encontrado'}
      </div>
    </div>
  );
}

ResultSarchSimple.propTypes = {
  keyword: PropTypes.string,
  total: PropTypes.number,
  selectedCategories: PropTypes.array,
  showFilters: PropTypes.bool,
  showFiltersCleaner: PropTypes.bool,
  handleClearFilters: () => {},
};

ResultSarchSimple.defaultProps = {
  showFilters: false,
  showFiltersCleaner: false,
};

export default ResultSarchSimple;
