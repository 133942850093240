import fetchCached from './fetchCached';

/**
 * Service para API de grupos
 */
class AutoServiceGroupApi {
  getGroupEndpoint(suffixEndpoint) {
    const group = document.querySelector(
      "meta[name='auto_api_channel']",
    ).content;

    return `/groups/${group}${suffixEndpoint}`;
  }

  /**
   * Retorna as `options` disponíveis para selects de filtros
   * @return {Promise}
   */
  getSelectFilterOptions(customParams = []) {
    return fetchCached(
      this.getGroupEndpoint('/used_models_filter'),
      customParams,
    );
  }

  getSelectFilterStockOptions(customParams = []) {
    const paramsDefault = [{ 'q[new_vehicle_eq]': '' }, ...customParams];
    return fetchCached(this.getGroupEndpoint('/stock_filter'), paramsDefault);
  }

  getUsedModels(customParams = []) {
    const paramsDefault = [
      { 'q[active_eq]': true },
      { 'q[new_vehicle_eq]': false },
      ...customParams,
    ];
    return fetchCached(this.getGroupEndpoint('/used_models'), paramsDefault, 1);
  }

  getUsedModelsSpecial(customParams = []) {
    const paramsDefault = [
      { 'q[active_eq]': true },
      { 'q[new_vehicle_eq]': true },
      ...customParams,
    ];
    return fetchCached(this.getGroupEndpoint('/used_models'), paramsDefault);
  }

  getUsedModelsFullStock(customParams = []) {
    const paramsDefault = [
      { 'q[active_eq]': true },
      { 'q[new_vehicle_eq]': '' },
      ...customParams,
    ];
    return fetchCached(this.getGroupEndpoint('/used_models'), paramsDefault);
  }

  getUnits() {
    return fetchCached(this.getGroupEndpoint('/units'));
  }

  getChannels() {
    return fetchCached(this.getGroupEndpoint('/channels?include=units'));
  }
}

export default new AutoServiceGroupApi();
