import { h, Component } from 'preact';
import CustomSelect from '../CustomSelect';
import CustomCheck from '../CustomCheck';
import FormMessageOverlay from '../FormMessageOverlay';
import SubmitButton from '../SubmitButton';
import PropTypes from 'prop-types';
import validationFactory from '%/utils/validation';
import IMask from 'imask';
import { phoneMasks } from '%/utils/masks';
import classNames from 'classnames';
import getUTM from '%/utils/utm';
import InputCpf from '../InputCpf';

export default class ConversionForm extends Component {
  constructor(props) {
    super(props);

    this.resetState()

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  resetState() {
    this.setState({
      name: '',
      email: '',
      phone: '',
      unit: '',
      message: '',
      phoning: true,
      mailing: true,
      whatsapping: true,
      cpf: '',
      model_car: '',
      versionValidStatus: true
    })
  }

  handleInputChange({ target }) {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [target.name]: value });
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf)
  }

  versionValid() {
    return this.state.model_car;
  }

  handleSubmit(e) {
    e.preventDefault();
    const formIsValid = this.validator.validateAll(this.form).length === 0;
    this.setState({ versionValidStatus: true });

    if (this.props.versions && this.props.versions.length > 0) {
      if (!this.versionValid()) {
        this.setState({ versionValidStatus: false });
        return
      }
    }

    if (!formIsValid) {
      return;
    }

    if (!this.cpfValid()) {
      alert('O campo de CPF é obrigatório')
      return
    }

    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, this.getFormData()).then(() => {
      this.resetState()
    })
  }

  componentDidMount() {
    const phoneMask = IMask(this.phoneInput, {
      mask: [...phoneMasks]
    });
    phoneMask.on("accept", () => this.setState({ phone: phoneMask.value }));
    
    this.validator = validationFactory(`#${this.props.formId}`);
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getFormData() {
    let state = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      unit: this.state.unit,
      message: this.state.message,
      mailing: this.state.mailing,
      phoning: this.state.phoning,
      whatsapping: this.state.whatsapping,
      model_car: this.state.model_car,
    }

    if (this.props.showCpf) {
      state.cpf = this.state.cpf
    }

    let props = {
      product: this.props.product,
      bait: -5,
      channel: this.props.channel,
      category: this.props.category,
      brand: this.props.brand,
      utmz: getUTM(),
    }

    if (this.props.unit) {
      props['unit'] = this.props.unit
    } else if (this.props.units.length === 1) {
      props['unit'] = this.props.units[0].value
    } else {
      props['unit'] = this.state.unit
    }

    return {...state, ...props}
  }

  render() {
    const { className, titleForm, showPlaceholder } = this.props

    return (
      <form
        className={classNames('conversion-form', className)}
        ref={form => (this.form = form)}
        id={this.props.formId}
        onSubmit={this.handleSubmit}
        novalidate
      >
        <FormMessageOverlay
          handleClose={this.props.handleCloseOverlay}
          isVisible={this.props.showOverlay}
          type={this.props.errorSendingForm ? 'error' : 'success'}
        />
        <header>
          { this.props.showIconExclamation &&
            <i className="icon icon-exclamation"></i>
          }
          <div className="conversion-form__header-phrase">
            <h2 class="form-conversion__title">{ titleForm }</h2>
            <p>{this.props.mainPhrase}</p>
          </div>
        </header>
        <div className="form-conversion__body">
          <fieldset>
            <div className="form-group">
              { this.props.showLabels &&
                <label className="control-label">Nome</label>
              }
              <input
                onChange={this.handleInputChange}
                value={this.state.name}
                type="text"
                maxLength={80}
                className="form-control"
                name="name"
                required
                placeholder={ showPlaceholder && 'Nome'}
                data-bouncer-target="#invalid-name"
              />
              <div id="invalid-name" className="invalid-feedback" />
            </div>
            <div className="form-group">
              { this.props.showLabels &&
                <label className="control-label">E-mail</label>
              }
              <input
                onChange={this.handleInputChange}
                value={this.state.email}
                type="email"
                maxLength={80}
                required
                className="form-control"
                name="email"
                placeholder={ showPlaceholder && 'E-mail' }
                data-bouncer-target="#invalid-email"
              />
              <div id="invalid-email" className="invalid-feedback" />
            </div>
            <div className="form-group">
              { this.props.showLabels &&
                <label className="control-label">Telefone</label>
              }
              <input
                onChange={this.handleInputChange}
                value={this.state.phone}
                ref={phoneInput => (this.phoneInput = phoneInput)}
                className="form-control"
                name="phone"
                required
                type="phone"
                data-bouncer-target="#invalid-phone"
                placeholder={ showPlaceholder && 'Telefone/Whatsapp' }
              />
              <div id="invalid-phone" className="invalid-feedback" />
            </div>


            { this.props.showCpf &&
              <InputCpf
                handleChange={this.handleInputChange}
                value={this.state.cpf}
                label={this.props.showLabels && 'Cpf'}
                placeholder={ showPlaceholder ? 'CPF' : '' }
              />
            }

            {this.props.versions && this.props.versions.length > 0 && (
              <div className="form-group">
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="model_car"
                  options={this.props.versions}
                  value={this.state.model_car}
                  shouldSort={false}
                  placeholderValue="Escolha um modelo"
                  searchEnabled={true}
                />

                {!this.state.versionValidStatus && (
                  <div id="invalid-version" class="invalid-feedback is-invalid-version">
                    <div class="error-message" id="bouncer-error_version">Por favor, selecione esse campo</div>
                  </div>
                )}
              </div>
            )}

            <div class="form-group">
              { !this.props.unit && this.props.units.length > 1 && this.props.showUnits &&
                <div className="form-group">
                  <label className="conversion-form__control-label">Escolha a unidade</label>
                  <CustomSelect
                    handleSelectChange={this.handleInputChange}
                    name="unit"
                    options={this.props.units}
                    value={this.state.unit}
                    shouldSort={false}
                    placeholderValue="Selecione uma unidade"
                    searchEnabled={false}
                  />
                </div>
              }
            </div>
            <div className="form-group">
            { this.props.showLabels &&
                <label className="control-label">Mensagem</label>
              }
              <textarea
                class="form-control"
                placeholder={ showPlaceholder && 'Mensagem' }
                rows="5"
                name="message"
                value={this.state.message}
                onChange={this.handleInputChange}
              >
              </textarea>
            </div>
          </fieldset>
          <fieldset>
            <legend class="conversion-form__control-label">Quero receber contato por:</legend>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="mailing"
                value="true"
                onChangeCheckable={this.handleInputChange}
                isChecked={this.state.mailing}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                E-mail
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="whatsapping"
                value="true"
                isChecked={this.state.whatsapping}
                onChangeCheckable={this.handleInputChange}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                Whatsapp
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="phoning"
                value="true"
                isChecked={this.state.phoning}
                onChangeCheckable={this.handleInputChange}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                Telefone
              </CustomCheck>
            </div>
          </fieldset>
          {this.props.shouldShowDataPermissions && (
            <fieldset className="mt-0 mb-2">
              <div className="form-check form-check-inline conversion-form__data-permissions-field mt-2">
                <CustomCheck
                  name="dataPermissions"
                  value="true"
                  isChecked={this.state.dataPermissions}
                  onChangeCheckable={this.handleInputChange}
                  type="checkbox"
                  checkStyle={this.props.checkStyle}
                >
                  { this.props.dataPermissionsCustomText }
                </CustomCheck>
              </div>
            </fieldset>
          )}
          <footer className="form-conversion__footer">
            Ao informar meus dados, eu concordo com a{' '}
            <a href={this.props.linkPrivacyPolicy}>Política de privacidade</a>.
          </footer>
          <hr className="my-3"></hr>
          <SubmitButton
            classes="btn button button--large button--primary w-100 mb-0"
            label="ENVIAR SOLICITAÇÃO"
            isSubmitting={this.props.isSubmittingForm}
            handleClick={this.handleSubmit}
          />
        </div>
      </form>
    );
  }
}

// default props
ConversionForm.defaultProps = {
  titleForm: 'Não encontrou o que procurava?',
  handleSubmit: function() {},
  handleCloseOverlay: function() {},
  isSubmittingForm: false,
  errorSendingForm: false,
  checkStyle: 'light',
  linkPrivacyPolicy: PropTypes.string,
  units: [],
  product: '',
  channel: '',
  category: '',
  brand: '-',
  showCpf: false,
  showIconExclamation: false,
  showLabels: false,
  showPlaceholder: true,
  versions: [],
  shouldShowDataPermissions: false,
};

// props config
ConversionForm.propTypes = {
  title: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCloseOverlay: PropTypes.func,
  mainPhrase: PropTypes.string,
  // Necessário para o Bouncer validar o form.
  formId: PropTypes.string.isRequired,
  isSubmittingForm: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  showOverlay: PropTypes.bool,
  checkStyle: PropTypes.oneOf(['dark', 'light']),
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showIconExclamation: PropTypes.bool,
  showLabels: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
};
