/* eslint-disable camelcase */
import { Component } from 'preact';
import PropTypes from 'prop-types';

export default class Item extends Component {
  getLink() {
    const { part_link, slug } = this.props;
    return `${part_link}/${slug}`;
  }

  render() {
    const {
      image,
      title,
      subtitle,
      item_offer,
      ctaInterestButtonText,
      titleOnHeader,
    } = this.props;

    return (
      <div className="card card--dark card-consortia">
        <div className="card__header">
          {titleOnHeader && (
            <div
              className="card__title-wrapper"
              data-match-height="card-consortia-card-title"
            >
              <div className="card__title">{title}</div>
              <div className="card__subtitle card-consortia__card-subtitle">
                {subtitle}
              </div>
            </div>
          )}
          {image && (
            <div className="card__img">
              <a href={this.getLink()}>
                <img className="card__img-value" src={image} alt={title} />
              </a>
            </div>
          )}
        </div>

        <div className="card__content text-center">
          {!titleOnHeader && (
            <div data-match-height="card-consortia-card-title">
              <div className="card__title">{title}</div>
              <div className="card__subtitle card-consortia__card-subtitle">
                {subtitle}
              </div>
            </div>
          )}
          <hr className="card__separator card-consortia__card-separator" />
          <div data-match-height="card-consortia-item-offer-total-value">
            {item_offer &&
              item_offer.total_value &&
              item_offer.total_value.secondary_call && (
                <div className="card__trigger-description card-consortia__card-trigger-description">
                  {item_offer.total_value.secondary_call}
                </div>
              )}
            {item_offer &&
              item_offer.total_value &&
              item_offer.total_value.featured && (
                <div>
                  <div className="card__trigger-value consortia__full-price">
                    {item_offer.total_value.featured}
                  </div>
                </div>
              )}
          </div>
          <hr className="card__separator card-consortia__card-separator" />
        </div>

        <div className="card__footer">
          <div data-match-height="card-consortia-card-footer">
            {item_offer &&
              item_offer.month_value &&
              item_offer.month_value.secondary_call && (
                <div className="card__trigger-description card-consortia__card-trigger-description">
                  {item_offer.month_value.secondary_call}
                </div>
              )}

            {item_offer &&
              item_offer.month_value &&
              item_offer.month_value.featured && (
                <div className="card__trigger-value consortia__price">
                  {item_offer.month_value.featured}
                </div>
              )}
            {item_offer &&
              item_offer.month_value &&
              item_offer.month_value.badge && (
                <div className="badge badge--pill card-consortia__badge">
                  {item_offer.month_value.badge}
                </div>
              )}
          </div>
          <a
            href={this.getLink()}
            className="card-consortia__button btn button button--block button--large card__cta"
          >
            {ctaInterestButtonText}
          </a>
        </div>
      </div>
    );
  }
}

Item.defaultProps = {
  ctaInterestButtonText: 'Estou Interessado',
  titleOnHeader: false,
};

Item.propTypes = {
  ctaInterestButtonText: PropTypes.string,
  titleOnHeader: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  part_link: PropTypes.string.isRequired,
  item_offer: PropTypes.shape({
    total_value: PropTypes.shape({
      secondary_call: PropTypes.string,
      featured: PropTypes.string,
    }),
    month_value: PropTypes.shape({
      secondary_call: PropTypes.string,
      featured: PropTypes.string,
      badge: PropTypes.string,
    }),
  }).isRequired,
};
